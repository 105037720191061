<template>
    <r-e-dialog title="抄表记录修改" :visible.sync="dialogVisible" width="710px" top="10vh" show-footer
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <div class="flex justify-between align-center flex-warp"
             style="background-color: #eff9ff;height: 50px;color: #333333;font-size: 15px;">
            <div style="margin-left: 20px">房间号 : {{ equipmentData.name }}</div>
        </div>
        <el-form ref="formPublish" label-width="135px" size="small" :model="formPublish" :rules="rules">
            <el-form-item label="记录当前水表数" v-if="type===0">
                <el-input v-model="water" placeholder="请输入内容" :disabled="true"/>
            </el-form-item>
            <el-form-item label="记录实际水表数" v-if="type===0" prop="water">
                <el-input v-model="formPublish.water" placeholder="请输入记录实际水表数"/>
            </el-form-item>
            <el-form-item label="记录当前电表数" v-if="type===1">
                <el-input v-model="elect" placeholder="请输入内容" :disabled="true"/>
            </el-form-item>
            <el-form-item label="记录真实电表数" v-if="type===1" prop="elect">
                <el-input v-model="formPublish.elect" placeholder="请输入内容"/>
            </el-form-item>
        </el-form>
    </r-e-dialog>
</template>

<script>
import {isNumeric} from "@custom";
import {MessageSuccess} from "@custom/message";
import {dcHydropowerUpdate} from "@/api/door-management";

export default {
    name: "dialog-hydropower-log-update",
    data() {
        let validateNumber = (rule, value, callback) => {
            if (!isNumeric(value)) {
                callback(new Error('请输入数字!'));
            } else {
                callback();
            }
        };
        return {
            dialogVisible: false,
            formPublish: {
                uuid: null,
                elect: null,
                water: null,
                transactionCode: null,
            },
            rules: {
                water: [
                    {required: true, message: '请输入记录实际水表数', trigger: 'blur'},
                    {validator: validateNumber, trigger: 'blur'}
                ],
                elect: [
                    {required: true, message: '请输入记录实际电表数', trigger: 'blur'},
                    {validator: validateNumber, trigger: 'blur'}
                ],
            },
            loadingOptions: {
                lock: true,
                text: 'Loading...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            },
            type: 0,
            water: 0,
            elect: 0,
        }
    },
    props: {
        equipmentData: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        async openDialog(data, type) {
            let that = this;
            const {uuid, water, elect} = data;
            that.formPublish.uuid = uuid;
            that.type = type;
            that.water = water;
            that.elect = elect;
            that.formPublish.transactionCode = Math.random();
            that.dialogVisible = true;
        },
        clickSubmit() {
            let that = this;
            this.$refs['formPublish'].validate(valid => {
                if (valid) {
                    let {uuid, water, elect, transactionCode} = that.formPublish;
                    water = parseFloat(water);
                    elect = parseFloat(elect);
                    const data = {uuid, transactionCode};
                    if (that.type === 0) {
                        data.water = water;
                    }
                    if (that.type === 1) {
                        data.elect = elect;
                    }
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    dcHydropowerUpdate(data).then(res => {
                        MessageSuccess("编辑成功");
                        that.clickCancel();
                    }).finally(() => loading.close());
                }
            });
        },
        clickCancel() {
            this.$emit('getHydropower', false);
            this.formPublish = {
                uuid: null,
                elect: null,
                water: null,
                transactionCode: null,
            }
            this.dialogVisible = false
        },
    },
}
</script>

<style scoped>

</style>